import BackHeader from "components/BackHeader";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import ButtonState from "../../../../components/common/ButtonState";
import Input from "../../../../components/Input";
import Tabs from "../../../../components/Tabs";
import styles from "./vehicle.module.scss";
import { useDateFormat } from "../../../../hooks/useDateFormat";
import { useGetVehicle } from "../../../../hooks/useGetVehicle";
import VehicleInformationForm from "../../../../components/VehicleInformationForm";
import { CreateVehicle, IVehicle } from "../../../../types/vehicles";
import { useUpdateVehicle } from "../../../../hooks/useUpdateVehicle";
import { updateVehicleStatusApi } from "../../../../api/vehicles";
import { checkExpiration } from "../../../../utils/checkExpiration";
import Select from "components/Select";
import Skeleton from "react-loading-skeleton";
import { DriverDocumentFilesRows } from "pages/Dashboard/Drivers/DriverItem/Documents/DriverDocumentFilesRows";
import { ExpiryAlertsContext } from "context/expiryAlerts";
import { toast } from "react-hot-toast";
import { successToastOptions } from "utils/toasterStyles";
import { LICENCE_TYPES } from "../../../../constants";
import { toastServerError } from "utils/helpers";

export const VehicleItem = () => {
  const { vehicleId } = useParams();
  const { vehicle } = useGetVehicle(vehicleId);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [registeredKeeper, setRegisteredKeeper] = useState<string | null>(null);
  const [MOTExpiryDate, setMOTExpiryDate] = useState<string | null>(null);
  const [insuranceExpiryDate, setInsuranceExpiryDate] = useState<string | null>(
    null
  );
  const [PHVLicenceType, setPHVLicenceType] = useState<string | null>(null);
  const [PHVLicenceNumber, setPHVLicenceNumber] = useState<
    string | null | undefined
  >(null);
  const [PHVLicenceExpiryDate, setPHVExpiryDate] = useState<string | null>(
    null
  );

  const [editableVehicleData, setEditableVehicleData] = useState<
    IVehicle | undefined
  >();

  const [isActive, setIsActive] = useState(vehicle?.isActive);
  const { updateExpiryAlerts } = useContext(ExpiryAlertsContext);

  const { format } = useDateFormat();

  const hasExpiringItem = useMemo(
    () =>
      [MOTExpiryDate, insuranceExpiryDate, PHVLicenceExpiryDate].some((date) =>
        checkExpiration(date)
      ),
    [MOTExpiryDate, insuranceExpiryDate, PHVLicenceExpiryDate]
  );

  const MOTDocument = vehicle?.documentDetails.find(
    (doc) => doc.category === "MOT"
  );
  const insuranceDocument = vehicle?.documentDetails.find(
    (doc) => doc.category === "Insurance"
  );
  const PHVDocument = vehicle?.documentDetails.find(
    (doc) => doc.category === "PHV"
  );

  useEffect(() => {
    if (vehicle) {
      setEditableVehicleData(vehicle);

      if (MOTDocument) {
        setMOTExpiryDate(format(MOTDocument.expiryDate));
      }

      if (insuranceDocument) {
        setInsuranceExpiryDate(format(insuranceDocument.expiryDate));
      }

      if (PHVDocument) {
        setPHVExpiryDate(format(PHVDocument.expiryDate));
        setPHVLicenceType(PHVDocument.type);
        setPHVLicenceNumber(PHVDocument.number);
      }

      setRegisteredKeeper(vehicle?.registeredKeeper);
    }
  }, [vehicle]);

  const { updateVehicle, updatedVehicle } = useUpdateVehicle(vehicleId);

  const handleSubmit = (data: Omit<CreateVehicle, "documentDetails">) => {
    return updateVehicle({
      ...data,
      registeredKeeper:
        registeredKeeper === null ? data?.registeredKeeper : registeredKeeper,
      documentDetails: [
        {
          id: MOTDocument?.id,
          category: "MOT",
          type: "TFL",
          expiryDate: MOTExpiryDate || "",
        },
        {
          id: insuranceDocument?.id,
          category: "Insurance",
          type: "TFL",
          //@ts-ignore
          expiryDate:
            insuranceExpiryDate || insuranceDocument?.expiryDate || "",
        },
        {
          id: PHVDocument?.id,
          category: "PHV",
          type: String(PHVLicenceType),
          number: PHVLicenceNumber || PHVDocument?.number || "",
          expiryDate: PHVLicenceExpiryDate || PHVDocument?.expiryDate || "",
        },
      ],
      ignoreAlert: Boolean(data.ignoreAlert),
    });
  };

  const renderTab = useCallback(() => {
    switch (currentTab) {
      case 0:
        return editableVehicleData ? (
          <VehicleInformationForm
            isCreate={false}
            vehicleData={editableVehicleData}
            setEditableVehicleData={setEditableVehicleData}
            onSubmit={handleSubmit}
          />
        ) : (
          <Skeleton
            className={styles.wrapper_header_info_location}
            height={600}
            width={"33vw"}
          />
        );
      case 1:
        return vehicle ? (
          <>
            <div className={styles.wrapper_container}>
              <div className={styles.wrapper_body}>
                <Input
                  isTextArea={true}
                  label="Name and Address of registered keeper"
                  value={registeredKeeper || ""}
                  onChange={(val) => {
                    setRegisteredKeeper(val);
                  }}
                />
              </div>
              <div className={styles.wrapper_row}>
                <div className={styles.wrapper_inputContainer}>
                  <Input
                    expiring={isActive && checkExpiration(MOTExpiryDate)}
                    type="date"
                    label="MOT expiry date"
                    value={MOTExpiryDate || ""}
                    onChange={(val) => setMOTExpiryDate(val)}
                    minDate={new Date()}
                  />
                </div>
                <div className={styles.wrapper_inputContainer}>
                  <Input
                    expiring={isActive && checkExpiration(insuranceExpiryDate)}
                    type="date"
                    label="Insurance expiry date"
                    value={insuranceExpiryDate || ""}
                    onChange={(val) => setInsuranceExpiryDate(val)}
                    minDate={new Date()}
                  />
                </div>
                <div className={styles.wrapper_inputContainer}>
                  <Select
                    label="PHV licence type"
                    data={LICENCE_TYPES}
                    selectedValue={PHVLicenceType || ""}
                    handleSelect={(value) => {
                      setPHVLicenceType(value);
                    }}
                  />
                </div>
                <div className={styles.wrapper_inputContainer}>
                  <Input
                    label="PHV licence number"
                    value={PHVLicenceNumber || ""}
                    onChange={(val) => setPHVLicenceNumber(val)}
                  />
                </div>
                <div className={styles.wrapper_inputContainer}>
                  <Input
                    expiring={isActive && checkExpiration(PHVLicenceExpiryDate)}
                    type="date"
                    label="PHV expiry date"
                    value={PHVLicenceExpiryDate || ""}
                    onChange={(val) => setPHVExpiryDate(val)}
                    minDate={new Date()}
                  />
                </div>
              </div>
              <DriverDocumentFilesRows driverId={vehicle.driver?.id} />
            </div>
          </>
        ) : null;
      default:
        return <p style={{ textAlign: "center" }}>default</p>;
    }
  }, [
    isActive,
    currentTab,
    vehicle,
    editableVehicleData,
    updatedVehicle,
    registeredKeeper,
    MOTExpiryDate,
    insuranceExpiryDate,
    PHVLicenceType,
    PHVLicenceNumber,
    PHVLicenceExpiryDate,
  ]);

  useEffect(() => {
    setIsActive(vehicle?.isActive);
  }, [vehicle?.isActive]);

  const handleStatusChange = async () => {
    if (vehicle?.id) {
      try {
        await updateVehicleStatusApi(vehicle?.id, {
          isActive: !Boolean(isActive),
        });
        setIsActive((prevState) => !prevState);
        toast("Vehicle status successfully updated", successToastOptions);
        updateExpiryAlerts();
      } catch (error) {
        toastServerError(
          error,
          "Something went wrong with vehicle status updating"
        );
      }
    }
  };

  return (
    <>
      <BackHeader
        title={"Vehicle"}
        onToggle={handleStatusChange}
        toggleActive={isActive}
      />
      <header className={styles.wrapper_header}>
        <div className={styles.wrapper_header_info}>
          <div className={styles.wrapper_header_text}>
            <div className={styles.wrapper_header_info_name}>
              {vehicle ? (
                <>
                  <span>
                    {vehicle.make} {vehicle.model}, {vehicle.colour}
                  </span>

                  <ButtonState isActive={isActive} />
                </>
              ) : (
                <Skeleton height={40} width={500} />
              )}
            </div>
            {vehicle ? (
              <span className={styles.wrapper_header_info_location}>
                {vehicle.registrationMark} {"\u00B7"} {vehicle.category}
              </span>
            ) : (
              <Skeleton
                className={styles.wrapper_header_info_location}
                height={20}
                width={500}
              />
            )}
          </div>
        </div>
      </header>
      <Tabs
        tabs={[
          { title: "General Information" },
          { title: "Documents", attention: hasExpiringItem && isActive },
        ]}
        currentIndex={currentTab}
        onChange={setCurrentTab}
      />
      {renderTab()}
    </>
  );
};
