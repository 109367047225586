// @ts-nocheck

import { useFormik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { VehicleSchema } from "utils/validation";
import { Category } from "../../types/drivers";
import { VariantButton } from "../../types/enums";
import { CreateVehicle, IVehicle } from "../../types/vehicles";
import DriverSelector from "../DriverSelector";
import { Button, Input } from "../index";
import Select from "../Select";
import toast from "react-hot-toast";
import { errorToastOptions, successToastOptions } from "utils/toasterStyles";
import { ExpiryAlertsContext } from "context/expiryAlerts";
import { ConfirmAlert } from "components/ConfirmAlert";
import { confirmAlert } from "react-confirm-alert";
import LoaderScreen from "components/LoaderScreen";
import { LICENCE_TYPES } from "../../constants";
import styles from "./styles.module.scss";

type Props = {
  setVehicles?: (vehicles: IVehicle[]) => void;
  vehicleData?: IVehicle;
  setEditableVehicleData?: (data: IVehicle) => void;
  isCreate: boolean;
  onSubmit: (data: CreateVehicle) => Promise<void | undefined>;
  onCancel?: () => void;
};
const emptyFormData: IVehicle = {
  id: -1,
  category: "",
  colour: "",
  model: "",
  isActive: false,
  expired: false,
  make: "",
  documents: [],
  registeredKeeper: "",
  registrationMark: "",
  driver: {
    id: -1,
    firstName: "",
    avatar: "",
    isActive: false,
    email: "",
    lastName: "",
    phoneNumber: "",
    expired: false,
    vehicle: {
      id: -1,
      category: Category.BUSINESS_XL,
    },
  },
  documentDetails: [
    {
      category: "MOT",
      type: "TFL",
      expiryDate: "",
      number: null,
    },
    {
      category: "Insurance",
      type: "TFL",
      expiryDate: "",
      number: null,
    },
    {
      category: "PHV",
      type: "",
      number: "",
      expiryDate: "",
    },
  ],
};

const CATEGORIES = [
  Category.FIRST_CLASS,
  Category.BUSINESS_PLUS,
  Category.BUSINESS_XL,
];

const MAKES = ["Mercedes", "BMW"];

const MODELS = [
  { make: "Mercedes", model: "V-Class" },
  { make: "Mercedes", model: "S-Class" },
  { make: "BMW", model: "7 Series" },
];
const COLOUR = ["Black", "White"];

const VehicleInformationForm: FC<Props> = ({
  vehicleData = emptyFormData,
  setEditableVehicleData,
  isCreate,
  onSubmit,
  onCancel,
  setVehicles,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { updateExpiryAlerts } = useContext(ExpiryAlertsContext);

  const { values, errors, setFieldValue, setFieldError, handleSubmit } =
    useFormik({
      initialValues: vehicleData,
      validationSchema: VehicleSchema,
      validateOnChange: false,
      onSubmit: (values) => {
        setIsLoading(true);
        const {
          driver,
          make,
          model,
          registeredKeeper,
          registrationMark,
          colour,
          category,
          documents,
          documentDetails,
        } = values;

        if (isCreate) {
          values.documentDetails &&
            onSubmit({
              model,
              make,
              category,
              registrationMark,
              driverId: Number(driver?.id),
              colour,
              registeredKeeper,
              documentDetails: [
                values.documentDetails[0],
                documentDetails[1],
                documentDetails[2],
              ],
            })
              .then((message) => {
                if (typeof message === "string") {
                  setIsLoading(false);
                  toast(
                    message === "jwt expired" ? "Token expired" : message,
                    errorToastOptions
                  );
                } else if (message?.success) {
                  setVehicles((prev) => [message.vehicle, ...prev]);
                  setIsLoading(false);
                  updateExpiryAlerts();
                  onCancel();
                }
              })
              .finally(() => {
                setIsLoading(false);
              });
        } else {
          onSubmit({
            model,
            make,
            category,
            registrationMark,
            driverId: Number(driver?.id),
            colour,
            registeredKeeper,
          })
            .then((message) => {
              if (typeof message !== "string") {
                toast("Vehicle succesfully updated!", successToastOptions);
                updateExpiryAlerts();
              } else if (
                message ===
                "This driver is already assigned to another car. Do you want to redefine it?"
              ) {
                confirmAlert({
                  customUI: ({ onClose }) => (
                    <ConfirmAlert
                      question={message}
                      onConfirm={async () => {
                        try {
                          await onSubmit({
                            model,
                            make,
                            category,
                            registrationMark,
                            driverId: Number(driver?.id),
                            colour,
                            registeredKeeper,
                            ignoreAlert: true,
                          });
                          onClose();
                          toast(
                            "Vehicle updated successfully!",
                            successToastOptions
                          );
                        } catch (error) {
                          toastServerError(
                            error,
                            "Something went wrong with vehicle updating"
                          );
                        }
                      }}
                      onClose={() => {
                        onClose();
                      }}
                    />
                  ),
                });
              }
            })
            .catch((error) => {
              toastServerError(
                error,
                "Something went wrong with vehicle updating!"
              );
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      },
    });

  useEffect(() => {
    if (values.make !== vehicleData.make) {
      setFieldValue("model", null);
    }
  }, [values.make]);

  useEffect(() => {
    return () => {
      setEditableVehicleData?.(values);
    };
  }, [values]);

  const handleValueChange = (valueName, value) => {
    setFieldValue(valueName, value);
    if (value) {
      setFieldError(valueName, "");
    }
  };

  return (
    <div className={styles.wrapper}>
      <Select
        label="Category"
        data={CATEGORIES}
        selectedValue={values.category}
        handleSelect={(val) => handleValueChange("category", val)}
        error={errors.category}
      />
      <Select
        label="Make"
        data={MAKES}
        selectedValue={values.make}
        handleSelect={(val) => handleValueChange("make", val)}
        error={errors.make}
      />
      {
        // @ts-ignore
        <Select
          label="Model"
          data={MODELS.map((item) => {
            if (item.make === values.make) {
              return item.model;
            }
          })}
          selectedValue={values.model}
          handleSelect={(val) => handleValueChange("model", val)}
          error={errors.model}
        />
      }
      <Input
        value={values.registrationMark}
        label="Registration Mark"
        error={errors.registrationMark}
        onChange={(val) => handleValueChange("registrationMark", val)}
      />
      <Select
        label="Colour"
        data={COLOUR}
        selectedValue={values.colour}
        handleSelect={(val) => handleValueChange("colour", val)}
        error={errors.colour}
      />
      <div className={styles.wrapper_homefields}>
        <h2 className={styles.subtitle}>Driver</h2>
        <DriverSelector
          selectedValue={String(values?.driver?.id)}
          setSelectedDriverId={(val) => handleValueChange("driver.id", val)}
          error={
            typeof errors?.driver === "string"
              ? errors?.driver
              : errors?.driver?.id
          }
        />
      </div>

      {isCreate ? (
        <div className={styles.wrapper_homefields}>
          <h2 className={styles.wrapper_title}>Document details</h2>
          <Input
            value={values.registeredKeeper}
            label="Name and Address of registered keeper"
            error={errors.registeredKeeper}
            onChange={(val) => handleValueChange("registeredKeeper", val)}
            isTextArea
          />
          <Input
            value={values.documentDetails[0].expiryDate}
            type="date"
            minDate={new Date()}
            label="MOT expiry date"
            error={
              errors?.documentDetails?.length &&
              errors.documentDetails[0].expiryDate
            }
            onChange={(val) =>
              handleValueChange("documentDetails[0].expiryDate", val)
            }
          />
          <Input
            value={values.documentDetails[1].expiryDate}
            type="date"
            label="Insurance expiry date"
            error={
              errors?.documentDetails?.length &&
              errors.documentDetails[1]?.expiryDate
            }
            minDate={new Date()}
            onChange={(val) =>
              handleValueChange("documentDetails[1].expiryDate", val)
            }
          />
          <Select
            label="PHV licence type"
            data={LICENCE_TYPES}
            selectedValue={values.documentDetails[2].type}
            error={
              errors?.documentDetails?.length && errors.documentDetails[2]?.type
            }
            handleSelect={(val) =>
              handleValueChange("documentDetails[2].type", val)
            }
          />
          <Input
            value={values.documentDetails[2].number}
            label="PHV licence number"
            error={
              errors?.documentDetails?.length &&
              errors.documentDetails[2]?.number
            }
            onChange={(val) =>
              handleValueChange("documentDetails[2].number", val || undefined)
            }
          />
          <Input
            value={values.documentDetails[2].expiryDate}
            type="date"
            label="PHV expiry date"
            minDate={new Date()}
            error={
              errors?.documentDetails?.length &&
              errors.documentDetails[2]?.expiryDate
            }
            onChange={(val) =>
              handleValueChange("documentDetails[2].expiryDate", val)
            }
          />
        </div>
      ) : null}
      <span className={styles.wrapper_line} />
      <div className={styles.wrapper_buttons}>
        <Button
          title={isCreate ? "Create Vehicle" : "Update details"}
          onClick={handleSubmit}
          variant={VariantButton.BLACK}
        />
        {onCancel && <Button title="Cancel" onClick={onCancel} />}
      </div>
      {isLoading && <LoaderScreen />}
    </div>
  );
};

export default VehicleInformationForm;
